/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect } from "react"
import {Link} from "gatsby"
import SEO from "../components/seo"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons"
import { lostPasswordCustomer, ChangePassWordAPI } from "../actions/auth"
import { clearLoading, showLoading } from "./../actions/index"
import Layout from "../components/layout"
import {logError} from "../services/log"

export const Motdepasse = () => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.loadingR)
  const panier = useSelector(state => state.panierR)

  const isBrowser = typeof window !== "undefined"
  const loginReset = isBrowser ? new URLSearchParams(window.location.search).get("login") : null
  const customerIDReset = isBrowser ? new URLSearchParams(window.location.search).get("id") : null
  const requestIDReset = isBrowser ? new URLSearchParams(window.location.search).get("requestID") : null
  const isConfirmation = isBrowser ? new URLSearchParams(window.location.search).get("confirmation") : null
  const displayRequest = loginReset === null || requestIDReset === null || customerIDReset === null;

  const [form, setForm] = useState({
    email: "",
    reponse: null,
  })
  const [form2, setForm2] = useState({
    login: displayRequest ? "" : loginReset,
    requestid: displayRequest ? 0 : requestIDReset,
    customerId: displayRequest ? 0 : customerIDReset,
    mdp: "",
    mdp2: "",
  })
  const [quest, setQuest] = useState(null)
  const [accounts, setAccounts] = useState([])

  useEffect(() => {
    const generateRandomQuestion = () => {
      const types = [
        { type: "nb", quest: "Combien de lettres y-a-t'il dans le mot «{xxx}» ?" },
      ]
  
      const words = ["bio", "fruit", "kiwi", "local", "cerise"]
      const type = types[rnd(0, types.length - 1)]
      const word = words[rnd(0, words.length - 1)]
      const letterPos = rnd(0, word.length - 1)
      const fr = ["première", "deuxième", "troisième", "quatrième", "cinquième", "sixème", "septième", "huitième", "neuvième", "dixième", "onzième"]
  
      switch (type.type) {
        case "nb":
          return { question: type.quest.replace("{xxx}", word), reponse: word.length }
        case "pos":
          return { question: type.quest.replace("{xxx}", word).replace("{x}", fr[letterPos]), reponse: word[letterPos] }
        default:
      }
    }
    const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

    if (displayRequest && !quest) {
      setQuest(generateRandomQuestion())
    }
  }, [displayRequest, quest])

  const handleUpdate = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleUpdateRequest = event => {
    setForm2({ ...form2, [event.target.name]: event.target.value })
  }

  const handleSubmitRequest = event => {
    event.preventDefault()
    if (form2.mdp !== "" && form2.mdp2 !== "" && form2.mdp === form2.mdp2) {
      dispatch(ChangePassWordAPI(form2.customerId, form2.requestid, form2.mdp, panier))
    } else {
      alert("Les deux mots de passe ne correspondent pas")
    }
  }

  const getAccounts = (e) => {
    e.preventDefault()
    if (parseInt(form.reponse.toLowerCase()) === parseInt(quest.reponse)) {
      dispatch(showLoading())
      fetch(process.env.GATSBY_API_URL + "/public/tofront/getLoginsApi", {
        method: "POST",
        headers: { Accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({ search: form.email }),
      }).then(response => response.json()).then(function (result) {
        dispatch(clearLoading(true, ""))
        if (!result.length) {
          alert('Aucun compte ne correspond aux données saisies, veuillez corriger votre recherche ou nous contacter')
        }
        else if (result.length < 2) {
          dispatch(lostPasswordCustomer(result[0].id))
        } else {
          setAccounts(result)
        }
      }).catch(function (error) {
        dispatch(clearLoading(false, ""))
        console.log("Request failed", error)
        alert("Une erreur s'est produite pendant la récupération de vos identifiants, veuillez nous contacter")
        logError('getLogins catch', {error, email: form.email})
      })
    } else {
      alert("La réponse à la question n'est pas bonne")
    }
  }

  const selectAccount = event => {
    if (event.target.value) {
      dispatch(lostPasswordCustomer(event.target.value))
    }
  }

  if (isConfirmation) {
    return (
      <Layout>
        <SEO title="Demande de mot de passe envoyée" description="Demande de mot de passe envoyée" />
        <div className="container p-5">
          <div className="message is-success p-5 m-5">
            <h2 className="almond is-size-3">Demande de mot de passe confirmée</h2>
            <div className="mt-3">Un lien vous a été envoyé par e-mail. Cliquez dessus et vous pourrez définir un nouveau mot de passe.</div>
            <h3 className="almond is-size-4 mt-5">Vous n'avez pas reçu d'email ?</h3>
            <div className="mt-3">Vérifiez votre dossier SPAM et patientez quelques minutes. Si vous ne voyez toujours pas d'e-mail, <Link to="/contact">contactez-nous</Link>.</div>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <>
<Layout>
      <SEO title="Mot de passe oublié" description="Mot de passe oublié" />
      <div className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-2 has-text-centered dreamland">Mot de passe oublie</h1>
          </div>
        </div>
      </div>
      <div className="container is-fluid pb-5 pattern-cross-dots">
        <div className="columns is-centered login">
          <div className="column is-half couleurShadow">

            {displayRequest ?
              <div className="box">
                <h2 className="is-size-4 mb-4" style={{ color: "#7e421e", paddingBottom: 5, borderBottom: "1px solid #ccc" }}>Vous avez oublié votre mot de passe ?</h2>
                {!accounts.length || accounts.length < 2 ? (
                  <form onSubmit={e => getAccounts(e)}>
                    <div className="field">
                      <label className="label" htmlFor="email">
                        Votre identifiant, adresse email ou n° de téléphone&nbsp;:
                      </label>
                      <div className="control">
                        <input type="text" id="email" name="email" onChange={e => handleUpdate(e)} required className="input" placeholder="Adresse email, identifiant Agrobioconso ou n° de téléphone" />
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <label className="label" htmlFor="reponse">
                            {quest !== null && quest.question}
                          </label>
                          <div className="control">
                            <input className="input" name="reponse" id="reponse" type="text" required onChange={e => handleUpdate(e)} style={{ width: "20%" }} />
                          </div>
                        </div>
                        <div className="field" style={{ color: "grey" }}>Un mail vous sera envoyé avec un lien pour changer votre mot de passe.</div>
                      </div>
                      <div className="column is-narrow">
                        <button type="submit" className={`button is-primary is-large dreamland ${loading.loading ? `is-loading` : ``}`} style={{ fontSize: "2rem", background: "#4d9f16" }} disabled={!form.email || form.email.length < 2 || !form.reponse}>
                          <FontAwesomeIcon icon={faCheck} className="mr-2" /> Valider
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <>
                    <div className="has-text-weight-bold">Vous avez plusieurs comptes, choisissez celui pour lequel vous souhaitez récupérer l'accès&nbsp;:</div>
                    <select className="select mt-3" onChange={e => selectAccount(e)}>
                      <option value="">Choisir mon compte adhérent</option>
                      {accounts.map(account => <option value={account.id} key={account.id}>{account.login} (rattaché au point de dépôt : {account.group_name})</option>)}
                    </select>
                  </>
                )}
              </div>
              :
              <div className="box">
                <form method="post" onSubmit={event => handleSubmitRequest(event)}>

                  <h2 className="is-size-4 mb-4" style={{ color: "#7e421e", paddingBottom: 5, borderBottom: "1px solid #ccc" }}>Changez votre mot de passe :</h2>
                  <div className="columns">

                    <div className="column">

                      <div className="field">
                        <label className="label" htmlFor="email">
                          Identifiant&nbsp;:
                        </label>
                        <div className="control">
                          <input type="text" id="login" name="login" value={form2.login} className="input" disabled />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label" htmlFor="email">
                          Nouveau mot de passe&nbsp;:
                        </label>
                        <div className="control">
                          <input type="password" id="mdp" name="mdp" onChange={e => handleUpdateRequest(e)} required className="input" placeholder="Votre nouveau mot de passe" />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label" htmlFor="email">
                          Confirmez nouveau mot de passe&nbsp;:
                        </label>
                        <div className="control">
                          <input type="password" id="mdp2" name="mdp2" onChange={e => handleUpdateRequest(e)} required className="input" placeholder="Confirmez votre nouveau mot de passe" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column has-text-centered">
                      <button type="submit" className={`button is-primary is-large dreamland ${loading.loading ? `is-loading` : ``}`} style={{ fontSize: "2rem", background: "#4d9f16" }}>
                        <FontAwesomeIcon icon={faCheck} className="mr-2" /> Valider
                      </button>
                    </div>
                  </div>
                </form>
              </div>}
          </div>
        </div>
      </div>
      </Layout>
    </>
  )
}


export default Motdepasse
